import { FaUserCircle, FaShoppingCart } from 'react-icons/fa'
import { LoginOutlined } from '@ant-design/icons'

export const privateRoutes = {
  orders: { to: '/', label: 'Orders', icon: FaShoppingCart, iconSize: 22 },
  profile: { to: '/profile', label: 'My Profile', icon: FaUserCircle, iconSize: 22, sidenav: false }
}
export const publicRoutes = {
  login: { to: '/sign-in', label: 'Login', icon: LoginOutlined }
}

export const LOCAL_CHANNELS = {
  RELOAD: 'RELOAD'
}

export const links = {
  ...privateRoutes,
  ...publicRoutes,
  LOCAL_CHANNELS
}

const vars = {
  links,
  linkedin_text_len: 3000,
  twitter_text_len: 280,
  social_text_len: 2200
}

export default vars
