import React from 'react'
import { Menu, Row } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'

import { privateRoutes } from 'config/vars'
import { isMobile } from 'helpers/utility'
import { Logo } from 'components/micro/Common'
import { toggleSidebar } from 'redux/slices/themeSlice'

function Sidenav() {
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const { showSidebar } = useSelector(state => state.theme)

  const closeNav = () => {
    if (!isMobile()) return
    dispatch(toggleSidebar())
  }

  return (
    <div className="flex h-screen flex-col">
      <div className="h-16 bg-slate-200">
        <div className="flex h-full w-full items-center justify-center">
          <Row justify="center" className="pointer-events-none select-none">
            <Logo width={135} rowProps={{ className: '' }} />
            <span className="ml-2 rounded bg-blue-100 px-2.5 py-0.5 text-xl font-semibold text-blue-800 dark:bg-blue-200 dark:text-blue-800">
              WRITER
            </span>
          </Row>
        </div>
      </div>

      <div className="flex-1 overflow-y-scroll">
        <Menu
          theme="light"
          mode="inline"
          className="app-bg"
          selectedKeys={[location.pathname]}
          items={Object.keys(privateRoutes)
            .filter(x => privateRoutes[x].sidenav !== false)
            .map(key => {
              const route = privateRoutes[key]
              const icon = route.icon ? <route.icon style={{ fontSize: route.iconSize || 22 }} /> : null

              return {
                key: route.to,
                icon,
                label: route.label,
                className: `font-semibold`,
                onClick: () => {
                  closeNav()
                  navigate(route.to)
                }
              }
            })}
        />
      </div>

      <div
        className="flex h-14 cursor-pointer items-center justify-center bg-slate-200 hover:opacity-75 md:h-16"
        onClick={() => dispatch(toggleSidebar())}
      >
        {React.createElement(showSidebar ? LeftOutlined : RightOutlined, {
          style: { fontSize: 20 }
        })}
      </div>
    </div>
  )
}

export default Sidenav
