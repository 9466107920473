import he from 'he'
import moment from 'moment'
import { htmlToText } from 'html-to-text'
import { Alert, Button, Modal, Popover, Result, Row, Spin, Tag } from 'antd'
import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  QuestionCircleTwoTone,
  ReloadOutlined,
  SyncOutlined,
  ZoomInOutlined,
  ZoomOutOutlined
} from '@ant-design/icons'

import keys from 'config/keys'

// Check empty
export const isEmpty = value =>
  value === undefined ||
  value === null ||
  (typeof value === 'object' && Object.keys(value).length === 0) ||
  (typeof value === 'string' && value.trim().length === 0)

export const getCssVar = (name, dom = document.documentElement) => {
  return getComputedStyle(dom).getPropertyValue(`--${name}`).trim()
}

export const getAllCssVars = () => ({
  primaryColor: getCssVar('primary-color'),
  secondaryColor: getCssVar('secondary-color'),
  infoColor: getCssVar('info-color'),
  successColor: getCssVar('success-color'),
  warnColor: getCssVar('warning-color'),
  dangerColor: getCssVar('danger-color'),
  textColor: getCssVar('main-text-color'),
  bodyBgColor: getCssVar('body-bg-color'),
  mainFontFamily: getCssVar('main-font'),
  baseFontSize: +(getCssVar('base-font-size')?.replace('px', '') ?? 14),
  baseBorderRadius: +(getCssVar('base-border-radius')?.replace('px', '') ?? 5)
})

export const breakpoints = {
  xs: '480px',
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
  xxl: '1600px'
}

export const getList = (len = 5) => {
  return Array(len)
    .fill(0)
    .map((_, i) => i)
}

export const isMobile = () => window.matchMedia(`(max-width: ${breakpoints.md})`).matches

export const isObject = val => typeof val === 'object' && !Array.isArray(val) && val !== null

export const renderLoading = ({ size = '', tip = '', className = '' } = {}) => {
  return (
    <Row justify="center" className={className}>
      <Spin size={size} tip={tip || undefined} />
    </Row>
  )
}

export const showConfirm = (callback, title = 'Are you sure about this action?', desc = '', okType = 'danger') => {
  confirm({
    title,
    icon: <QuestionCircleTwoTone />,
    content: desc,
    onOk: () => callback(),
    okText: 'Yes',
    okType
  })
}

export const getErrorAlert = ({ msg, className = 'mt-3', onRetry, fullScreen = false }) => {
  const alert = (
    <Alert
      className={fullScreen ? '' : className}
      message={msg || 'Something went wrong. Try again!'}
      showIcon
      type="error"
      action={
        onRetry ? (
          <Button type="primary" icon={<ReloadOutlined />} onClick={onRetry}>
            Try Again
          </Button>
        ) : undefined
      }
    />
  )

  if (fullScreen) {
    return (
      <div className="flex h-screen items-center justify-center">
        <div className="mx-3 mb-5 w-full max-w-xl">{alert}</div>
      </div>
    )
  }

  return alert
}

export const isScript = input =>
  input.includes('&lt;script&gt;') ||
  input.includes('&lt;/script&gt;') ||
  input.includes('<script>') ||
  input.includes('</script>')

export const truncate = (_input = '', title, length = 5, tooltip = true) => {
  const input = _input ?? ''
  const val = he.decode(htmlToText(input.substring(0, length), { wordwrap: 130 }))
  return input.length > length ? (
    tooltip ? (
      <Popover
        title={title}
        content={
          isScript(input) ? (
            <strong style={{ color: 'tomato' }}>Quick view is unavailable as it&apos;s contain JavaScript.</strong>
          ) : (
            <div dangerouslySetInnerHTML={{ __html: input }} />
          )
        }
        overlayInnerStyle={{ maxWidth: 450, maxHeight: 400, overflowY: 'scroll' }}
      >
        {val}...
      </Popover>
    ) : (
      `${val}...`
    )
  ) : (
    input
  )
}

export const readableTime = (value, full = false) => moment(value).format(full ? 'LLLL' : 'ddd, MMM Do YY')
export const readableDate = value => moment(value).format('MMM Do YY')

export const basePasswordRule = {
  required: true,
  pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{10,})/,
  message: 'Password minimum length is 10. Including one uppercase, lowercase, number and special character.'
}

const pageSizeOptions = [50, 100, 250, 500, 1000, 1500, 2000]
export const defaultPaginationConfig = {
  showSizeChanger: true,
  defaultPageSize: pageSizeOptions[0],
  hideOnSinglePage: false,
  showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
  pageSizeOptions
}

export const renderBoolTag = (value, successColor = getCssVar('primary-color')) => {
  if (value) {
    return (
      <Tag className="m-0" color={successColor} style={{ minWidth: 30 }}>
        Yes
      </Tag>
    )
  }
  return (
    <Tag className="m-0" color="red" style={{ minWidth: 35 }}>
      No
    </Tag>
  )
}

export const commonBoolColProps = dataIndex => ({
  dataIndex,
  align: 'center',
  width: 60,
  render: val => renderBoolTag(val)
})

export const getOrderStatusTag = (status, record, className = 'w-100') => {
  if (record.done) {
    return (
      <Tag color={getCssVar('success-color')} icon={<CheckCircleOutlined />} className={className}>
        Complete
      </Tag>
    )
  }

  switch (status) {
    case keys.ORDER_STATUS.CANCELLED:
      return (
        <Tag icon={<CloseCircleOutlined />} color="error" className={className}>
          {status}
        </Tag>
      )

    case keys.ORDER_STATUS.PROGRESS:
      return (
        <Tag icon={<SyncOutlined spin />} color="processing" className={className}>
          {status}
        </Tag>
      )

    case keys.ORDER_STATUS.PUBLISHED:
      return (
        <Tag icon={<CheckCircleOutlined />} color="success" className={className}>
          {status}
        </Tag>
      )

    case keys.ORDER_STATUS.READY:
      return (
        <Tag icon={<ClockCircleOutlined />} color="cyan" className={className}>
          {status}
        </Tag>
      )

    case keys.ORDER_STATUS.ADMIN_PENDING_REVIEW:
      return (
        <Tag icon={<ClockCircleOutlined />} color="tomato" className={className}>
          {status}
        </Tag>
      )

    case keys.ORDER_STATUS.CLIENT_PENDING_REVIEW:
      return (
        <Tag icon={<ClockCircleOutlined />} color="#87d068" className={className}>
          {status}
        </Tag>
      )

    case keys.ORDER_STATUS.REFUNDING:
      return (
        <Tag icon={<ExclamationCircleOutlined />} color="warning" className={className}>
          {status}
        </Tag>
      )

    default:
      return (
        <Tag color="default" className={className}>
          {status}
        </Tag>
      )
  }
}

export const getOrderStatusColProps = dataIndex => {
  return {
    dataIndex,
    align: 'center',
    width: 60,
    render: (val, record) => getOrderStatusTag(val, record)
  }
}

export const getActiveColumn = (props = {}) => {
  return { title: 'Active', fixed: 'left', ...commonBoolColProps('active'), ...props }
}

export const removeLocalValues = (_values = {}) => {
  const values = { ..._values }
  if (isEmpty(values)) return values

  Object.keys(values).forEach(key => {
    if (key.startsWith(keys.LOCAL_PREFIX)) delete values[key]
  })

  return values
}

// Artificially wait
export const sleep = ms => {
  return new Promise(resolve => setTimeout(resolve, ms))
}

// Get base64 of a file
export const getBase64 = file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}

export const getReadableSize = size => {
  let _size = size
  const fSExt = ['Bytes', 'KB', 'MB', 'GB']
  let i = 0

  while (_size > 900) {
    _size /= 1024
    i++
  }

  const exactSize = Math.round(_size * 100) / 100 + ' ' + fSExt[i]
  return exactSize
}
export const isNewFileSelected = file => file && typeof file === 'object' && !file.secure_url

export const getSizeFromBase64 = base64String => {
  // Without 'data:image/png;base64,' part
  const stringLength = base64String.length
  const sizeInBytes = 4 * Math.ceil(stringLength / 3) * 0.5624896334383812
  return sizeInBytes
}

const getBase64Prefix = type => `data:${type};base64,`
const commonImageTypes = ['png', 'jpeg', 'gif', 'apng', 'avif', 'webp', 'svg+xml']
export const cleanBase64ForAjax = (data, type, fileType = 'image/') => {
  let _data = data
  commonImageTypes.forEach(__type => {
    _data = _data.replace(getBase64Prefix(`${fileType}${__type}`), '')
  })
  if (type) {
    _data = _data.replace(getBase64Prefix(type), '')
  }
  // For unknown type
  _data = _data.replace(getBase64Prefix(`application/octet-stream`), '')
  return _data
}
export const getUploadedFileObjForAjax = (file, data) => {
  const type = file.type
  const _data = cleanBase64ForAjax(data, type)

  return {
    name: file.filename,
    size: getSizeFromBase64(_data),
    type,
    data: _data
  }
}
const plainTxtType = 'text/plain'
const txtExtension = '.txt'
export const getFileObjForAjax = (file, data, needClean = true) => {
  const type = file.type || plainTxtType
  const suffix = type === plainTxtType && !file.name?.endsWith?.(txtExtension) ? txtExtension : ''
  return {
    name: (file.name || file.filename) + suffix,
    size: file.size,
    type,
    data: needClean ? cleanBase64ForAjax(data, type) : data
  }
}

export const getReadableCurrency = (value, space = true) =>
  `$${space ? ' ' : ''}${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')

export const getReadableFileSize = size => {
  let _size = size
  const fSExt = ['Bytes', 'KB', 'MB', 'GB']
  let i = 0

  while (_size > 900) {
    _size /= 1024
    i++
  }

  const exactSize = Math.round(_size * 100) / 100 + ' ' + fSExt[i]
  return exactSize
}

export const getLastAntdDrawerBody = () => {
  const arr = document.querySelectorAll('.ant-drawer-body')
  if (isEmpty(arr)) return null
  return arr[arr.length - 1]
}

export const checkHasError = form => !!form.getFieldsError().filter(({ errors }) => errors.length).length

function isValidUrl(url) {
  try {
    const _url = new URL(url)
    return [true, _url]
  } catch (error) {
    return [false, {}]
  }
}

const urlRegex =
  /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/

export const validateUrl = (rule, value) => {
  return new Promise((resolve, reject) => {
    if (!value) {
      resolve()
    } else {
      const [valid, url] = isValidUrl(value)
      if (valid && urlRegex.test(value) && (url.protocol === 'http:' || url.protocol === 'https:')) {
        resolve()
      } else {
        reject('Must be a valid URL with a scheme matching the http or https pattern')
      }
    }
  })
}

export const showResultModal = ({ status = 'success', title = 'Successful!', subTitle } = {}) => {
  Modal.success({
    className: 'full-width',
    centered: true,
    closable: true,
    footer: null,
    icon: null,
    maskClosable: true,
    content: <Result status={status} title={title} subTitle={subTitle} />
  })
}

export const antdPreviewCommonProps = {
  icons: {
    left: null,
    right: null,
    flipX: null,
    flipY: null,
    rotateLeft: null,
    rotateRight: null,
    zoomIn: <ZoomInOutlined />,
    zoomOut: <ZoomOutOutlined />,
    close: <CloseCircleOutlined />
  }
}

export const getLastAntdEl = (cls = '.ant-modal-body') => {
  const arr = document.querySelectorAll(cls)
  if (isEmpty(arr)) return null
  return arr[arr.length - 1]
}

export function capitalizeString(str) {
  if (typeof str !== 'string' || str.length === 0) {
    return str // Return the original value if it's not a string or an empty string
  }

  // Capitalize the first letter and concatenate it with the rest of the string
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export const isOverdue = targetDate => targetDate && moment(targetDate).isBefore(moment())
export const timeLeft = targetDate => (targetDate ? moment(targetDate).diff(moment()) : null)

export const formatTimeLeft = targetDate => {
  const diff = timeLeft(targetDate)
  const duration = moment.duration(diff)

  return `${capitalizeString(duration.humanize())} left`
}

export const getTimeLeftTagColor = (v, asTxtColor = false) => {
  const error = asTxtColor ? 'text-red-500 font-bold' : 'error'
  const warn = asTxtColor ? 'text-yellow-500' : 'warning'
  return isOverdue(v) ? error : timeLeft(v) <= 86400000 ? warn : ''
}
