import Axios from 'axios'
import React from 'react'
import { useMount } from 'ahooks'
import { useSelector } from 'react-redux'

import endpoints from 'config/endpoints'

const delay = 1000 * 60 * 4 // 4 minutes

function UpdateLastActiveTime() {
  const _isMounted = React.useRef(false)
  const { isAuthenticated } = useSelector(state => state.auth)

  const handleUpdate = async () => {
    try {
      await Axios.post(endpoints.writerBase + `/online`)
    } catch (error) {
      console.log('Last active time update error:', error)
    }
  }

  useMount(() => {
    handleUpdate()
  })

  React.useEffect(() => {
    _isMounted.current = true

    const intervalHandler = setInterval(() => {
      if (_isMounted.current && isAuthenticated) {
        handleUpdate()
      }
    }, delay)

    return () => {
      clearInterval(intervalHandler)
      _isMounted.current = false
    }
  }, [])

  return null
}

export default React.memo(UpdateLastActiveTime)
